import React, { useState } from "react"
import { Media, useIsLessThanMd, FlexColumn } from "@opensea/ui-kit"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"
import styled, { css } from "styled-components"
import { Block } from "@/design-system/Block"
import { Carousel } from "@/design-system/Carousel"
import { useAnnouncementBanner } from "@/features/announcement-banner/utils/context"
import { PrimaryDropProvider } from "@/features/primary-drops/containers/PrimaryDropProvider"
import { PrimaryDropSpotlight_data$key } from "@/lib/graphql/__generated__/PrimaryDropSpotlight_data.graphql"
import { media } from "@/styles/styleUtils"
import { CATEGORY_BOTTOM_PADDING } from "@/styles/variables"
import { nonInteractiveOverlay } from "../../styleUtils"
import { Banner } from "../Banner"
import { PrimaryDropsBannerImage } from "../PrimaryDropsBannerImage"
import { ResponsiveText } from "../ResponsiveText"

export type PrimaryDropSpotlightProps = {
  collectionKey: PrimaryDropSpotlight_data$key
  title?: string
  eventSource: string
}

export const PrimaryDropSpotlight = ({
  collectionKey,
  title,
  eventSource,
}: PrimaryDropSpotlightProps) => {
  const data = useFragment<PrimaryDropSpotlight_data$key>(
    graphql`
      fragment PrimaryDropSpotlight_data on CollectionType
      @relay(plural: true) {
        slug
        ...Banner_data
        ...PrimaryDropProviderMintProgress_data
        metadata {
          hero {
            desktopHeroMedia {
              ...PrimaryDropsBannerImage_data
            }
            mobileHeroMedia {
              ...PrimaryDropsBannerImage_data
            }
          }
        }
      }
    `,
    collectionKey,
  )
  const multipleDrops = data.length > 1

  // need to default to true here, because swiper navigation breaks if you go
  // from no navigation => navigation, but it can handle the other way around
  const enableArrows = !useIsLessThanMd()
  const firstDrop = data[0]
  const [metadata, setMetadata] = useState(firstDrop.metadata)
  const { spotlightTopOffset } = useAnnouncementBanner()

  return (
    <SpotlightContainer $topOffset={spotlightTopOffset}>
      <BackgroundContainer>
        <Media lessThan="md">
          <PrimaryDropsBannerImage
            dataKey={metadata?.hero?.mobileHeroMedia ?? null}
          />
        </Media>
        <Media greaterThanOrEqual="md">
          <PrimaryDropsBannerImage
            dataKey={metadata?.hero?.desktopHeroMedia ?? null}
          />
        </Media>
      </BackgroundContainer>
      {title && (
        <ResponsiveText.Display
          asChild
          className="z-10 -mt-14 mb-12 text-white"
          size="large"
        >
          <h1>{title}</h1>
        </ResponsiveText.Display>
      )}
      {multipleDrops ? (
        <Block marginX={{ _: "-34px", sm: "0px" }}>
          <Carousel
            autoplayConfig={{
              delay: 5000,
              pauseOnMouseEnter: true,
              disableOnInteraction: false,
            }}
            centeredSlides
            // eslint-disable-next-line tailwindcss/no-custom-classname
            className="drop-spotlight-carousel"
            enableArrowControls={enableArrows}
            enableFreeScroll
            enableMousewheel
            handleActiveIndexChange={index => {
              const drop = data[index]
              setMetadata(drop.metadata)
            }}
            id="drop-spotlight-carousel"
            loop={data.length > 2 || enableArrows}
            renderSlide={({ item }) => item}
            responsiveConfig={{
              xs: {
                slidesPerView: 1.25,
                slidesPerGroup: 1,
                spaceBetween: 8,
              },
              default: {
                slidesPerView: 1,
                slidesPerGroup: 1,
                spaceBetween: 16,
              },
            }}
            showScrollbar={false}
            slides={data.map(dropSpotlight => {
              return {
                id: dropSpotlight.slug || "",
                item: (
                  <PrimaryDropProvider
                    collectionSlug={dropSpotlight.slug}
                    dataKey={dropSpotlight}
                  >
                    <Banner
                      dataKey={dropSpotlight}
                      eventSource={eventSource}
                      redirectToCollectionOnDropEnd
                    />
                  </PrimaryDropProvider>
                ),
              }
            })}
            variant="spotlight"
          />
        </Block>
      ) : (
        <PrimaryDropProvider
          collectionSlug={firstDrop.slug}
          dataKey={firstDrop}
        >
          <Banner
            dataKey={firstDrop}
            eventSource={eventSource}
            redirectToCollectionOnDropEnd
          />
        </PrimaryDropProvider>
      )}
    </SpotlightContainer>
  )
}

const BackgroundContainer = styled(Block)`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  &:after {
    backdrop-filter: blur(20px);
    background: linear-gradient(
      0deg,
      ${props => props.theme.colors.base1} 5%,
      rgba(0, 0, 0, 0) 60%
    );
    ${nonInteractiveOverlay}
    background-color: rgba(0, 0, 0, 0.6);
  }
`
const SpotlightContainer = styled(FlexColumn)<{
  $topOffset: number
}>`
  position: relative;
  margin-top: -${props => props.$topOffset}px;
  overflow: hidden;
  width: 100%;
  position: relative;
  margin-bottom: 24px;
  ${props => {
    const paddingOffset = props.$topOffset + CATEGORY_BOTTOM_PADDING
    return media({
      xs: css`
        padding-top: ${paddingOffset - 16}px;
        padding-left: 16px;
        padding-right: 16px;
      `,
      sm: css`
        padding-left: 32px;
        padding-right: 32px;
      `,
      md: css`
        padding-top: ${paddingOffset}px;
      `,
      lg: css`
        padding-top: ${paddingOffset}px;
      `,
      xl: css`
        padding-top: ${paddingOffset}px;
      `,
      xxl: css`
        padding-top: ${paddingOffset}px;
        padding-left: 64px;
        padding-right: 64px;
      `,
    })
  }}
`
