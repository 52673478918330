/**
 * @generated SignedSource<<cd0b6f13782e56157b9e7e599d864b36>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PrimaryDropSpotlight_data$data = ReadonlyArray<{
  readonly metadata: {
    readonly hero: {
      readonly desktopHeroMedia: {
        readonly " $fragmentSpreads": FragmentRefs<"PrimaryDropsBannerImage_data">;
      };
      readonly mobileHeroMedia: {
        readonly " $fragmentSpreads": FragmentRefs<"PrimaryDropsBannerImage_data">;
      };
    } | null;
  } | null;
  readonly slug: string;
  readonly " $fragmentSpreads": FragmentRefs<"Banner_data" | "PrimaryDropProviderMintProgress_data">;
  readonly " $fragmentType": "PrimaryDropSpotlight_data";
}>;
export type PrimaryDropSpotlight_data$key = ReadonlyArray<{
  readonly " $data"?: PrimaryDropSpotlight_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"PrimaryDropSpotlight_data">;
}>;

const node: ReaderFragment = (function(){
var v0 = [
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "PrimaryDropsBannerImage_data"
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "PrimaryDropSpotlight_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Banner_data"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PrimaryDropProviderMintProgress_data"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CollectionMetadataType",
      "kind": "LinkedField",
      "name": "metadata",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "HeroMetadataType",
          "kind": "LinkedField",
          "name": "hero",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "desktopHeroMedia",
              "plural": false,
              "selections": (v0/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "mobileHeroMedia",
              "plural": false,
              "selections": (v0/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CollectionType",
  "abstractKey": null
};
})();

(node as any).hash = "28e3956c61b80b2963ba26efd30d0a89";

export default node;
